import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";

const ID_KEY = "7HfcDyjGFpGfOgelA3Ts";
const SECRET_KEY = "lWp6Nn1eED";
function App() {
  const getSearchMovie = async () => {
    await axios.get("https://openapi.naver.com/v1/search/movie.json", {
      params: {
        query: "토르",
        display: 20,
      },
      headers: {
        "X-Naver-Client-Id": ID_KEY,
        "X-Naver-Client-Secret": SECRET_KEY,
      },
    });
  };

  useEffect(() => {
    getSearchMovie();
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
